
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { BaseService } from './base.service';
import { environment } from '../../../environments/environment';
import { ILevels, IMiniTask, IMiniTaskLog, IMonsterLogs } from '../models/model';

@Injectable({ providedIn: 'root' })
export class CritterService extends BaseService {

    constructor(protected override http: HttpClient) {
        super(http);
    }
    private apiUrl = environment.apiUrl + '/monster';

    getCritters() {
        return this.get(this.apiUrl)
    }

    getIfNoTasksYet() {
        return this.get<{ status: boolean, firstTask: any }>(this.apiUrl + '/no-tasks')
    }

    getCurrentChallenges() {
        return this.get(this.apiUrl + '/my-challenges')
    }

    getLogActivityDetails(id: string) {
        return this.get<IMonsterLogs>(this.apiUrl + `/log-activity-details/`, { id })
    }

    getCritterDetail(id: string) {
        return this.get(this.apiUrl + `/critter/${id}`)
    }

    getTodaysMiniTask() {
        return this.get<any>(this.apiUrl + '/todays-mini-task')
    }

    getMiniTaskLog(id: string) {
        return this.get<IMiniTaskLog>(this.apiUrl + `/mini-task-log/` + id)
    }
    getLevelByLogId(id: string) {
        return this.get<IMonsterLogs>(this.apiUrl + '/level', { id })
    }

    startChallenge(id: string) {
        return this.post<string>(this.apiUrl + '/start-challenge', { level: id })
    }

    saveActivity(logId: string, data: any) {
        return this.post<{
            status: string
            message: string
        }>(this.apiUrl + '/save-activity', { ...data, logId })
    }
    getLogActivityStatus(logId: string) {
        return this.get<boolean>(this.apiUrl + '/log-activity-status', { logId })
    }

    logActivity(logId: string, data: any) {
        return this.post<{
            status: string
            message: string
        }>(this.apiUrl + '/update-activity', { ...data, logId })
    }

    logMiniTask(data: any) {
        return this.post<boolean>(this.apiUrl + '/log-mini-task', data)
    }

    getCurrentLogById(id: string) {
        return this.get(this.apiUrl + `/log`, { id })
    }

    getChallengeStatus(data: any) {
        return this.get(this.apiUrl + `/challenge-status`, data)
    }


    getBadges() {
        return this.get<IMonsterLogs[]>(this.apiUrl + '/badges')
    }

    getStats() {
        return this.get(this.apiUrl + '/stats')
    }
    getStreak() {
        return this.get<{
            streak: number
        }>(this.apiUrl + '/streak')
    }

    quitChallenge(data: any) {
        return this.post(this.apiUrl + '/quit-challenge', data)
    }

}